import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAppDispatch } from '../../hooks/redux';
import { resetFilters } from '../../store/slices/allFiltersReducer';

const NavigationArray = [
  { name: 'Консорциум', path: '/consortium' },
  { name: 'Общая информация', path: '/general-info' },
  { name: 'Все потоки', path: '/all-flows' },
  { name: 'Все организации', path: '/all-organizations' },
  { name: 'Статистика по слушателям', path: '/listener-statistics' },
  { name: 'Данные с мониторинга', path: '/monitoring-data' },
];

export const Navbar = () => {
  const [activePath, setActivePath] = useState<number>();
  const location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(resetFilters([]));
    const path = NavigationArray.findIndex((item) => item.path === location.pathname);
    setActivePath(path);
  }, [location]);
  return (
    <div className="navbar">
      {NavigationArray.map((el, index) => (
        <div className={`navbar__links ${activePath === index ? 'active' : ''}`} key={index}>
          <Link to={el.path}>{el.name}</Link>
        </div>
      ))}
    </div>
  );
};
