import React from 'react';
import { FC, Fragment, useEffect } from 'react';
import { Loader } from '../components/loader';
import { useAppDispatch, useAppSelector } from '../hooks/redux';
import { getToken } from '../store/slices/authReducer';

export const Auth: FC = (props) => {
  const dispatch = useAppDispatch();
  const { loading, token } = useAppSelector((state) => state.authReducer);

  useEffect(() => {
    dispatch(getToken());
  }, []);

  const iconSize = 48;

  const wrapperCN = 'w-screen h-screen flex items-center justify-center';
  const blockCN = 'max-w-xs flex flex-col items-center gap-y-4 p-4 text-primary-darkBlue font-semibold text-xl text-center';

  if (loading) {
    return (
      <div className={wrapperCN}>
        <div className={blockCN}>
          <Loader width={iconSize} height={iconSize} />
          <span>Выполняется проверка авторизации</span>
        </div>
      </div>
    );
  }

  if (typeof token !== 'string') {
    window.unione.goToAuth();

    return (
      <div className={wrapperCN}>
        <div className={blockCN}>
          <Loader width={iconSize} height={iconSize} />
          <span>Переход на страницу авторизации...</span>
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      {props.children}
    </Fragment>
  );
};
