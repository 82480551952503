import { createSlice } from '@reduxjs/toolkit';

export type Filter = {
  filterName: string;
  position: string;
};

export type FilterState = {
  filters: Filter[];
};

export const initialState: FilterState = {
  filters: [],
};

const allFilters = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    addFilter(state, action) {
      state.filters.push({
        filterName: action.payload.filterName,
        position: action.payload.position,
      });
    },
    removeFilter(state, action) {
      state.filters = state.filters.filter((filter) => filter.position !== action.payload);
    },
    resetFilters(state, action) {
      state.filters = action.payload;
    },
  },
});

export const { addFilter, removeFilter, resetFilters } = allFilters.actions

export const allFiltersReducer = allFilters.reducer
