import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { convertToYears } from '../../helpers/convertToYears';
import { RootFlow } from '../../helpers/interfaces'

interface IInitialState {
  isLoading: boolean;
  flow1: {
    [key: string]: number;
  };
  flow2: {
    [key: string]: number;
  };
  flow3: {
    [key: string]: number;
  };
  box1: {
    [key: string]: number[];
  };
}

const domain = process.env.REACT_APP_DOMAIN;
const data = [
  {
    key: 'flow1',
    href: `${domain}/api/v1/chart/?q=(filters:!((col%3Aslice_name,opr%3Achart_all_text%2Cvalue%3A%27Прошедшие+потоки%27))%2Corder_column%3Achanged_on_delta_humanized%2Corder_direction%3Adesc%2Cpage%3A0%2Cpage_size%3A25)`,
    name: 'Прошедшие потоки',
  },
  {
    key: 'flow2',
    href: `${domain}/api/v1/chart/?q=(filters:!((col%3Aslice_name,opr%3Achart_all_text%2Cvalue%3A%27Запланированные+потоки%27))%2Corder_column%3Achanged_on_delta_humanized%2Corder_direction%3Adesc%2Cpage%3A0%2Cpage_size%3A25)`,
    name: 'Запланированные потоки',
  },
  {
    key: 'flow3',
    href: `${domain}/api/v1/chart/?q=(filters:!((col%3Aslice_name,opr%3Achart_all_text%2Cvalue%3A%27Текущие+потоки%27))%2Corder_column%3Achanged_on_delta_humanized%2Corder_direction%3Adesc%2Cpage%3A0%2Cpage_size%3A25)`,
    name: 'Текущие потоки',
  },
  {
    key: 'one-flow',
    href: `${domain}/api/v1/chart/?q=(filters:!((col%3Aslice_name,opr%3Achart_all_text%2Cvalue%3A%27один+поток%27))%2Corder_column%3Achanged_on_delta_humanized%2Corder_direction%3Adesc%2Cpage%3A0%2Cpage_size%3A25)`,
    name: 'один поток',
  },
  {
    key: 'first-flow',
    href: `${domain}/api/v1/chart/?q=(filters:!((col%3Aslice_name,opr%3Achart_all_text%2Cvalue%3A%27первый+модуль%27))%2Corder_column%3Achanged_on_delta_humanized%2Corder_direction%3Adesc%2Cpage%3A0%2Cpage_size%3A25)`,
    name: 'первый модуль',
  },
  {
    key: 'second-flow',
    href: `${domain}/api/v1/chart/?q=(filters:!((col%3Aslice_name,opr%3Achart_all_text%2Cvalue%3A%27второй+модуль%27))%2Corder_column%3Achanged_on_delta_humanized%2Corder_direction%3Adesc%2Cpage%3A0%2Cpage_size%3A25)`,
    name: 'второй модуль',
  },
  {
    key: 'third-flow',
    href: `${domain}/api/v1/chart/?q=(filters:!((col%3Aslice_name,opr%3Achart_all_text%2Cvalue%3A%27третий+модуль%27))%2Corder_column%3Achanged_on_delta_humanized%2Corder_direction%3Adesc%2Cpage%3A0%2Cpage_size%3A25)`,
    name: 'третий модуль',
  },
];

const promises = data.map(
  (item) => new Promise((resolve) => {
    resolve(fetch(item.href).then((result) => result.json()));
  }),
);

export const fetchFlowGraphData = createAsyncThunk(
  'flowGraph/get',
  async () => Promise.all(promises),
);

const initialState: IInitialState = {
  isLoading: true,
  flow1: {},
  flow2: {},
  flow3: {},
  box1: { ALL_YEARS: [] },
};

const collectIds = (obj: object): object => {
  const filtered = Object.entries(obj).filter(([key]) => key.endsWith('-flow'));
  const keys = Object.keys(filtered[0][1]);
  const result = keys.reduce((acc: any, current: any) => {
    acc[current] = [];
    for (const [, years] of filtered) {
      acc[current].push(years[current]);
    }
    return acc;
  }, {});
  return result;
};

const flowGraph = createSlice({
  name: 'flowGraph',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
  
    builder.addCase(fetchFlowGraphData.fulfilled, (state, action): any => {
      const convertedToYears: any = action.payload.reduce((acc: any, current: any, index): RootFlow => {
        acc[data[index].key] = convertToYears(current.result, data[index].name);
        return acc;
      }, {});

      return {
        flow1: convertedToYears.flow1,
        flow2: convertedToYears.flow2,
        flow3: convertedToYears.flow3,
        box1: collectIds(convertedToYears),
        isLoading: false,
      };
    });
  },
});

export const flowGraphReducer = flowGraph.reducer;
