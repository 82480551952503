import React, { useState } from 'react';
import { Skelet } from '../components/skeleton/Skelet';
import { flowGraphPath } from '../config';
import '../styles/supersetFrame.scss';

export const FlowGraph = () => {
  const [load, setLoad] = useState<boolean>(true)

  return (
    <div className="flowGraph">
      <Skelet hide={!load} />
      <iframe
        scrolling='no'
        id="frame"
        className='superset-frame'
        src={flowGraphPath}
        hidden={load}
        onLoad={() => {
          setLoad(false)
        }}>
      </iframe>
    </div>
  );
};
