import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { convertToYears } from '../../helpers/convertToYears';

interface IInitialState {
  isLoading: boolean;
  tab2_sub1: {
    [key: string]: number;
  };
  tab2_sub2: {
    [key: string]: number;
  };
  tab2_sub3: {
    [key: string]: number;
  };
  tab3_sub1: {
    [key: string]: number;
  };
  tab3_sub2: {
    [key: string]: number;
  };
  tab3_sub3: {
    [key: string]: number;
  };
}

interface IData {
  key: string;
  href: string;
  name: string;
}

const domain = process.env.REACT_APP_DOMAIN;
const data: IData[] = [
  {
    key: 'tab2_sub1',
    href: `${domain}/api/v1/chart/?q=(filters:!((col%3Aslice_name,opr%3Achart_all_text%2Cvalue%3A%27%D0%9F%D0%BE+%D1%82%D0%B8%D0%BF%D1%83%20%D0%BE%D0%B1%D1%80%D0%B0%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D0%BE%D0%B9%20%D0%BE%D1%80%D0%B3%D0%B0%D0%BD%D0%B8%D0%B7%D0%B0%D1%86%D0%B8%D0%B8%27))%2Corder_column%3Achanged_on_delta_humanized%2Corder_direction%3Adesc%2Cpage%3A0%2Cpage_size%3A25)`,
    name: 'По типу образовательной организации',
  },
  {
    key: 'tab2_sub2',
    href: `${domain}/api/v1/chart/?q=(filters:!((col%3Aslice_name,opr%3Achart_all_text%2Cvalue%3A%27%D0%9F%D0%BE+%D0%BD%D0%B0%D0%BF%D1%80%D0%B0%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D1%8E%27))%2Corder_column%3Achanged_on_delta_humanized%2Corder_direction%3Adesc%2Cpage%3A0%2Cpage_size%3A25)`,
    name: 'По направлению',
  },
  {
    key: 'tab3_sub1',
    href: `${domain}/api/v1/chart/?q=(filters:!((col%3Aslice_name,opr%3Achart_all_text%2Cvalue%3A%27%D0%9F%D0%BE+%D0%BE%D1%82%D1%80%D0%B0%D1%81%D0%BB%D1%8F%D0%BC%27))%2Corder_column%3Achanged_on_delta_humanized%2Corder_direction%3Adesc%2Cpage%3A0%2Cpage_size%3A25)`,
    name: 'По отраслям',
  },
  {
    key: 'tab3_sub2',
    href: `${domain}/api/v1/chart/?q=(filters:!((col%3Aslice_name,opr%3Achart_all_text%2Cvalue%3A%27По+регионам%27))%2Corder_column%3Achanged_on_delta_humanized%2Corder_direction%3Adesc%2Cpage%3A0%2Cpage_size%3A25)`,
    name: 'По регионам',
  },
  {
    key: 'tab3_sub3',
    href: `${domain}/api/v1/chart/?q=(filters:!((col%3Aslice_name,opr%3Achart_all_text%2Cvalue%3A%27По+программам%27))%2Corder_column%3Achanged_on_delta_humanized%2Corder_direction%3Adesc%2Cpage%3A0%2Cpage_size%3A25)`,
    name: 'по программам',
  },
];

const promises = data.map(
  (item) =>
    new Promise((resolve) => {
      resolve(fetch(item.href).then((result) => result.json()));
    })
);

export const fetchAllFlowsData = createAsyncThunk(
  'allFlows/get',
  async () => await Promise.all(promises)
);

const initialState: IInitialState = {
  isLoading: true,
  tab2_sub1: {},
  tab2_sub2: {},
  tab2_sub3: {},
  tab3_sub1: {},
  tab3_sub2: {},
  tab3_sub3: {},
};

const allFlows = createSlice({
  name: 'allFlows',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllFlowsData.fulfilled, (state, action) => {
      return {
        ...state,
        ...Object.assign(
          {},
          action.payload.reduce((acc: any, current: any, index) => {
            acc[data[index].key] = convertToYears(current.result, data[index].name);
            return acc;
          }, {})
        ),
        isLoading: false,
      };
    });
  },
});

export const allFlowsReducer = allFlows.reducer
