export interface IYears {
  [key: string]: number;
}

export const convertToYears = (array: [], baseString: string): IYears => array.reduce((obj: any, current: any) => {
  const { slice_name } = current;
  if (!String(slice_name).includes(baseString)) {
    return obj;
  }
  const splitted = slice_name.split(' ');
  const last = splitted[splitted.length - 1];
  const isSomeYear = !isNaN(+last);
  isSomeYear ? (obj[`FLOW_${last}`] = current.id) : (obj.ALL_YEARS = current.id);
  return obj;
}, {});
