import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { convertToYears } from '../../helpers/convertToYears';

interface IInitialState {
  isLoading: boolean;
  table2: {
    [key: string]: number;
  };
}

const domain = process.env.REACT_APP_DOMAIN;
const data = [
  {
    key: 'table2',
    href: `${domain}/api/v1/chart/?q=(filters:!((col%3Aslice_name,opr%3Achart_all_text%2Cvalue%3A%27По+наименованию%20образовательной%20организации%27))%2Corder_column%3Achanged_on_delta_humanized%2Corder_direction%3Adesc%2Cpage%3A0%2Cpage_size%3A25)`,
    name: 'По наименованию образовательной организации',
  },
];

const promises = data.map(
  (item) =>
    new Promise((resolve) => {
      resolve(fetch(item.href).then((result) => result.json()));
    })
);

export const fetchAllOrganizationsData = createAsyncThunk(
  'allOrganizations/get',
  async () => await Promise.all(promises)
);

const initialState: IInitialState = {
  isLoading: true,
  table2: {},
};

const allOrganizations = createSlice({
  name: 'allOrganizations',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllOrganizationsData.fulfilled, (state, action) => {
      return {
        ...state,
        ...Object.assign(
          {},
          action.payload.reduce((acc: any, current: any, index) => {
            acc[data[index].key] = convertToYears(current.result, data[index].name);
            return acc;
          }, {})
        ),
        isLoading: false,
      };
    });
  },
});

export const allOrganizationsReducer = allOrganizations.reducer;
