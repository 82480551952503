import React from 'react';
import { FC, SVGProps } from 'react';
import cn from 'classnames';
import { LoaderIcon } from '../icons/loader'

export const Loader: FC<SVGProps<SVGSVGElement>> = (props) => {
  const loaderProps: SVGProps<SVGSVGElement> = {
    ...props,
    className: cn(
      props.className,
      'animate-spin text-primary-darkBlue',
    ),
  };

  return (
    <LoaderIcon {...loaderProps} />
  );
};
