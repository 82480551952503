import React, { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useAppDispatch } from '../../hooks/redux';
import { fetchAllFlowsData } from '../../store/slices/allFlowsReducer';
import { fetchAllOrganizationsData } from '../../store/slices/allOrganizationsReducer';
import { fetchFlowGraphData } from '../../store/slices/flowGraphReducer';
import { fetchListenerStatisticsData } from '../../store/slices/listenerStatisticsReducer';
import { fetchMainOrganizationsData } from '../../store/slices/mainOrganizationsReducer';
import {
  AllFlows,
  AllOrganization,
  Consortium,
  FlowGraph,
  GeneralInfo,
  ListenerStatistics,
  MainOrganizations,
  MonitoringData
} from '../../views';
import { Navbar } from '../navbar/Navbar';
import { Auth } from '../../auth/auth.view';
import { Assesment } from '../../views/Assesment';
import { CodeFuture } from '../../views/CodeFuture';
import '../../styles/main.scss';

export const App = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchAllFlowsData());
    dispatch(fetchAllOrganizationsData());
    dispatch(fetchFlowGraphData());
    dispatch(fetchMainOrganizationsData());
    dispatch(fetchListenerStatisticsData());
  }, [dispatch]);

  return (
    <>
      <div>
        <Routes>
          <Route path="/consortium" element={<Auth><Navbar /><Consortium /></Auth>} />
          <Route path="/general-info" element={<div><Navbar /><GeneralInfo /></div>} />
          <Route path="/all-flows" element={<Auth><Navbar /><AllFlows /></Auth>} />
          <Route path="/all-organizations" element={<Auth><Navbar /><AllOrganization /></Auth>} />
          <Route path="/main-organizations" element={<Auth><Navbar /><MainOrganizations /></Auth>} />
          <Route path="/flow-chart" element={<Auth><Navbar /><FlowGraph /></Auth>} />
          <Route path="/listener-statistics" element={<Auth><Navbar /><ListenerStatistics /></Auth>} />
          <Route path="/monitoring-data" element={<Auth><Navbar /><MonitoringData /></Auth>} />
          <Route path="/consortium" element={<Navigate to="/consortium" />} />
          <Route path="/code_future" element={<Auth><CodeFuture/></Auth>} />
          <Route path="/" element={<div><Navbar /><GeneralInfo /></div>} />
        </Routes>
      </div>
    </>
  );
};
