import { dashboardPath } from "./helpers/dashboard.constants";

export const codeFuturePath = process.env.REACT_APP_DOMAIN + dashboardPath.code_future;
export const assesmentPath = process.env.REACT_APP_DOMAIN + dashboardPath.assesment;
export const monitoringPath = process.env.REACT_APP_DOMAIN + dashboardPath.monitoring_data;
export const mainOrganizationsPath = process.env.REACT_APP_DOMAIN + dashboardPath.main_organizations;
export const listenerStatisticsPath = process.env.REACT_APP_DOMAIN + dashboardPath.listener_statistics;
export const generalInfoPath = process.env.REACT_APP_DOMAIN + dashboardPath.general_info;
export const flowGraphPath = process.env.REACT_APP_DOMAIN + dashboardPath.flow_graph;
export const consortiumPath = process.env.REACT_APP_DOMAIN + dashboardPath.consortium;
export const allOrgPath = process.env.REACT_APP_DOMAIN + dashboardPath.all_organization;
export const allFlowsPath = process.env.REACT_APP_DOMAIN + dashboardPath.all_flows;
