import React, { useState } from 'react';
import { generalInfoPath } from '../config';
import '../styles/supersetFrame.scss';
import { Skelet } from '../components/skeleton/Skelet';

export const GeneralInfo = () => {
  const [load, setLoad] = useState<boolean>(true)

  return (
    <div>
      <Skelet hide={!load} />
      <iframe
        scrolling='no'
        id="frame"
        className='superset-frame general-info-st'
        src={generalInfoPath}
        hidden={load}
        onLoad={() => {
          setLoad(false)
        }}>
      </iframe>
    </div>
  );
};
