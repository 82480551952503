import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export interface UserAuth {
  token?: string;
  loading: boolean;
  error?: string;
}

export const initialState: UserAuth = {
  token: undefined,
  loading: true,
  error: undefined
};

export const getToken = createAsyncThunk(
  'auth/getToken',
  async () => {
    try {
      const unioneToken = await window.unione.getUnioneToken();
      if (typeof unioneToken === 'string' && unioneToken !== 'null' && unioneToken.length > 0) {
        return unioneToken;
      }
      return undefined;
    }
    catch (error) {
      console.error(error);
    }
  }
)

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getToken.pending, state => {
        state.loading = true;
      })
      .addCase(getToken.fulfilled, (state, action) => {
        state.loading = false;
        state.token = action.payload;
      })
      .addCase(getToken.rejected, state => {
        state.loading = false;
        state.error = 'error';
      })
  }
});

export const authReducer = auth.reducer;
