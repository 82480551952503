import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { convertToYears } from '../../helpers/convertToYears';

interface IInitialState {
  isLoading: boolean;
  table1: {
    [key: string]: number;
  };
  table3: {
    [key: string]: number;
  };
  box5: {
    [key: string]: number[];
  };
  box10: {
    [key: string]: number[];
  };
}

const domain = process.env.REACT_APP_DOMAIN;
const data = [
  {
    key: 'table3',
    href: `${domain}/api/v1/chart/?q=(filters:!((col%3Aslice_name,opr%3Achart_all_text%2Cvalue%3A%27Головные+образовательные%20организации%20(ОО)%27))%2Corder_column%3Achanged_on_delta_humanized%2Corder_direction%3Adesc%2Cpage%3A0%2Cpage_size%3A25)`,
    name: 'Головные образовательные организации (ОО) по регионам (таблица)',
  },
  // {
  //   key: 'table3',
  //   href: `${domain}/api/v1/chart/?q=(filters:!((col%3Aviz_type,opr%3Aeq%2Cvalue%3Acountry_map)%2C(col%3Aslice_name%2Copr%3Achart_all_text%2Cvalue%3A%27Головные+образовательные%20организации%20(ОО)%27))%2Corder_column%3Achanged_on_delta_humanized%2Corder_direction%3Adesc%2Cpage%3A0%2Cpage_size%3A25)`,
  //   name: 'Головные образовательные организации (ОО) по регионам',
  // },
  // {
  //   key: 'box5',
  //   href: `${domain}/api/v1/chart/?q=(filters:!((col%3Aslice_name,opr%3Achart_all_text%2Cvalue%3A%27Головных+ОО%27))%2Corder_column%3Achanged_on_delta_humanized%2Corder_direction%3Adesc%2Cpage%3A0%2Cpage_size%3A25)`,
  //   name: 'Головных ОО, направивших слушателей на обучение',
  // },
  // {
  //   key: 'box10',
  //   href: `${domain}/api/v1/chart/?q=(filters:!((col%3Aslice_name,opr%3Achart_all_text%2Cvalue%3A%27Регионов%2C+направивших%20слушателей%20на%20обучение%27))%2Corder_column%3Achanged_on_delta_humanized%2Corder_direction%3Adesc%2Cpage%3A0%2Cpage_size%3A25)`,
  //   name: 'Регионов, направивших слушателей на обучение',
  // },
];

const promises = data.map(
  (item) =>
    new Promise((resolve) => {
      resolve(fetch(item.href).then((result) => result.json()));
    })
);

export const fetchMainOrganizationsData = createAsyncThunk(
  'mainOrganizations/get',
  async () => await Promise.all(promises)
);

const initialState: IInitialState = {
  isLoading: true,
  table1: {
    ALL_YEARS: 296,
    FLOW_2022: 994,
    FLOW_2023: 1011,
    FLOW_2024: 1012,
  },
  table3: {},
  box5: {
    ALL_YEARS: [291, 292, 293, 294],
    FLOW_2022: [1058, 1065, 1069, 1072],
    FLOW_2023: [1061, 1066, 1070, 1073],
    FLOW_2024: [1064, 1068, 1071, 1074],
  },
  box10: {
    ALL_YEARS: [295, 213],
    FLOW_2022: [1075, 213],
    FLOW_2023: [1076, 213],
    FLOW_2024: [1077, 213],
  },
};

const mainOrganizations = createSlice({
  name: 'mainOrganizations',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMainOrganizationsData.fulfilled, (state, action) => {
      return {
        ...state,
        ...Object.assign(
          {},
          action.payload.reduce((acc: any, current: any, index: number) => {
            acc[data[index].key] = convertToYears(current.result, data[index].name);
            return acc;
          }, {})
        ),
        isLoading: false,
      };
    });
  },
});

export const mainOrganizationsReducer = mainOrganizations.reducer;
