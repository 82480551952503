import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { allFiltersReducer } from './slices/allFiltersReducer';
import { allFlowsReducer } from './slices/allFlowsReducer';
import { allOrganizationsReducer } from './slices/allOrganizationsReducer';
import { flowGraphReducer } from './slices/flowGraphReducer';
import { listenerStatisticsReducer } from './slices/listenerStatisticsReducer';
import { mainOrganizationsReducer } from './slices/mainOrganizationsReducer';
import { authReducer } from './slices/authReducer';

const rootReducer = combineReducers({
  allFlowsReducer,
  flowGraphReducer,
  allOrganizationsReducer,
  mainOrganizationsReducer,
  listenerStatisticsReducer,
  allFiltersReducer,
  authReducer,
});

export const setupStore = () => configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
