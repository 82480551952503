import React, { FC } from 'react';
import { Skeleton } from 'antd';
import { ISkeletProps } from '../../helpers/interfaces';

export const Skelet: FC<ISkeletProps> = ({ hide }) => {

  return (
    <div
      hidden={hide}
      className='superset-frame skeleton-container'>
      <Skeleton className='skeleton' active />
      <Skeleton className='skeleton' active />
      <Skeleton className='skeleton' active />
    </div>

  );
};
