import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { convertToYears } from '../../helpers/convertToYears';

interface IInitialState {
  isLoading: boolean;
  bar12: {
    [key: string]: number;
  };
  bar6: {
    [key: string]: number;
  };
  bar4: {
    [key: string]: number;
  };
  bar5: {
    [key: string]: number;
  };
  bar10: {
    [key: string]: number;
  };
  line2: {
    [key: string]: number;
  };
}

const domain = process.env.REACT_APP_DOMAIN;
const data = [
  {
    key: 'bar6',
    href: `${domain}/api/v1/chart/?q=(filters:!((col%3Aslice_name,opr%3Achart_all_text%2Cvalue%3A%27Движение+слушателей%20по%20текущим%20потокам%27))%2Corder_column%3Achanged_on_delta_humanized%2Corder_direction%3Adesc%2Cpage%3A0%2Cpage_size%3A25)`,
    name: 'Движение слушателей по текущим потокам',
  },
  {
    key: 'bar5',
    href: `${domain}/api/v1/chart/?q=(filters:!((col%3Aslice_name,opr%3Achart_all_text%2Cvalue%3A%27Распределение+слушателей%20по%20отраслям%27))%2Corder_column%3Achanged_on_delta_humanized%2Corder_direction%3Adesc%2Cpage%3A0%2Cpage_size%3A25)`,
    name: 'Распределение слушателей по отраслям',
  },
  {
    key: 'line2',
    href: `${domain}/api/v1/chart/?q=(filters:!((col%3Aslice_name,opr%3Achart_all_text%2Cvalue%3A%27Роль+по%20потокам%27))%2Corder_column%3Achanged_on_delta_humanized%2Corder_direction%3Adesc%2Cpage%3A0%2Cpage_size%3A25)`,
    name: 'Роль по потокам',
  },
  {
    key: 'bar10',
    href: `${domain}/api/v1/chart/?q=(filters:!((col%3Aslice_name,opr%3Achart_all_text%2Cvalue%3A%27По+роли%27))%2Corder_column%3Achanged_on_delta_humanized%2Corder_direction%3Adesc%2Cpage%3A0%2Cpage_size%3A25)`,
    name: 'По роли',
  },
  {
    key: 'bar12',
    href: `${domain}/api/v1/chart/?q=(filters:!((col%3Aslice_name,opr%3Achart_all_text%2Cvalue%3A%27По+возрасту%27))%2Corder_column%3Achanged_on_delta_humanized%2Corder_direction%3Adesc%2Cpage%3A0%2Cpage_size%3A25)`,
    name: 'По возрасту',
  },
  {
    key: 'bar4',
    href: `${domain}/api/v1/chart/?q=(filters:!((col%3Aslice_name,opr%3Achart_all_text%2Cvalue%3A%27По+типу%20ОО%27))%2Corder_column%3Achanged_on_delta_humanized%2Corder_direction%3Adesc%2Cpage%3A0%2Cpage_size%3A25)`,
    name: 'По типу ОО',
  },
];

const promises = data.map(
  (item) =>
    new Promise((resolve) => {
      resolve(fetch(item.href).then((result) => result.json()));
    })
);

export const fetchListenerStatisticsData = createAsyncThunk(
  'listenerStatistics/get',
  async () => await Promise.all(promises)
);

const initialState: IInitialState = {
  isLoading: true,
  bar12: {},
  bar6: {},
  bar4: {},
  bar5: {},
  bar10: {},
  line2: {},
};

const listenerStatistics = createSlice({
  name: 'listenerStatistics',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchListenerStatisticsData.fulfilled, (state, action) => {
      return {
        ...state,
        ...Object.assign(
          {},
          action.payload.reduce((acc: any, current: any, index) => {
            acc[data[index].key] = convertToYears(current.result, data[index].name);
            return acc;
          }, {})
        ),
        isLoading: false,
      };
    });
  },
});

export const listenerStatisticsReducer = listenerStatistics.reducer;
