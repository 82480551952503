interface IdentifierPath {
  [identifier: string]: string;
}

export const dashboardPath: IdentifierPath = {
  code_future: '/superset/dashboard/code_future/?standalone=true',
  assesment: '/superset/dashboard/assesment_dashboard/?standalone=true',
  all_flows: '/superset/dashboard/31/?standalone=true',
  all_organization: '/superset/dashboard/30/?standalone=true',
  consortium: '/superset/dashboard/28/?standalone=true',
  flow_graph: '/superset/dashboard/17/?standalone=true',
  general_info: '/superset/dashboard/29/?standalone=true',
  listener_statistics: '/superset/dashboard/27/?standalone=true',
  main_organizations: '/superset/dashboard/13/?standalone=true',
  monitoring_data: '/superset/dashboard/33/?standalone=true',
};
