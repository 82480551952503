import React, { useState } from 'react';
import { Skelet } from '../components/skeleton/Skelet';
import { listenerStatisticsPath } from '../config';
import '../styles/supersetFrame.scss';

export const ListenerStatistics = () => {
  const [load, setLoad] = useState<boolean>(true)

  return (
    <div>
      <Skelet hide={!load} />
      <iframe
        scrolling='no'
        id="frame"
        className='superset-frame statistics-st'
        src={listenerStatisticsPath}
        hidden={load}
        onLoad={() => {
          setLoad(false)
        }}>
      </iframe>
    </div>
  );
};
